<template>
  <SlideoutContainer size="small">
    <Slideout>
      <template #header>
        <Slab :size="'large'">
          <template #primary-action>
            <Icon
              name="close"
              size="small"
              type="outlined"
              @click="closeSlideout"
            ></Icon>
          </template>
          Container Details
        </Slab>
      </template>
      <Section>
        <!-- Details Section -->
        <Slab type="transparent">
          <Accordion :open="detailsAcc">
            <template #header>
              <Slab type="transparent">
                <template #primary-action>
                  <Icon name="i-info-fill" size="small" type="filled" />
                </template>
                Details
                <Button
                  :disabled="pdfButton.loading"
                  :type="pdfButton.type"
                  class="margin-left-37"
                  size="normal"
                  @click="downloadPDF"
                >
                  Download PDF
                </Button>
                <template #secondary-actions>
                  <Icon
                    :rotate="detailsAcc ? 180 : 0"
                    name="chevron"
                    size="small"
                    @click="detailsAcc = !detailsAcc"
                  />
                </template>
              </Slab>
            </template>
            <div
              v-for="detail in slideoutDetails.details"
              :key="detail.key"
              class="flow-grid-column padding-10"
              label="cloud services"
            >
              <div class="display-flex">
                <span class="min-width-150 align-text-left fc-light">
                  {{ detail.key }}</span
                >
                <span> {{ detail.value }}</span>
              </div>
            </div>
          </Accordion>
        </Slab>
        <!-- Images Section  -->
        <Slab type="transparent">
          <Accordion id="image-acc" :open="imageAcc">
            <template #header>
              <Slab type="transparent">
                <template #primary-action>
                  <Icon name="i-camera-1" size="small" type="filled" />
                </template>
                Images
                <Button
                  :disabled="zipButton.loading"
                  :type="zipButton.type"
                  class="margin-left-15"
                  size="normal"
                  @click="downloadZip"
                >
                  Download Images
                </Button>
                <template #secondary-actions>
                  <Icon
                    :rotate="imageAcc ? 180 : 0"
                    name="chevron"
                    size="small"
                    @click="imageAcc = !imageAcc"
                  />
                </template>
              </Slab>
            </template>
            <VueEasyLightbox
              :imgs="lightboxImg"
              :visible="isLightboxVisible"
              class="lighthouse_component"
              @hide="hideLightbox"
            ></VueEasyLightbox>
            <div class="display-grid">
              <div
                v-for="imgg in slideoutDetails.images"
                :key="imgg[0]"
                class="img-div-inside-slideout"
              >
                <div>{{ wall_number(imgg[0]) }}</div>
                <img
                  :id="imgg[0]"
                  :alt="imgg[0]"
                  :src="imgg[1]"
                  class="padding-10 image-inside-slideout"
                  @click="openImageModal(imgg[0], imgg[1], imgg)"
                />
              </div>
            </div>
          </Accordion>
        </Slab>
      </Section>
    </Slideout>
  </SlideoutContainer>
</template>
<script>
import {
  Accordion,
  Button,
  Icon,
  Section,
  Slab,
  Slideout,
  SlideoutContainer,
} from "@cldcvr/flow";
import { mapGetters } from "vuex";
// import mediumZoom from "medium-zoom";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "ContainerDetailsSlideout",
  components: {
    SlideoutContainer,
    Slideout,
    Slab,
    Icon,
    Section,
    Accordion,
    Button,
    VueEasyLightbox,
  },
  data: () => ({
    zipButton: {
      loading: false,
      type: "primary",
    },
    pdfButton: {
      loading: false,
      type: "primary",
    },
    detailsAcc: true,
    imageAcc: true,
    zoom: null,
    lightboxImg: "",
    isLightboxVisible: false,
  }),
  computed: {
    ...mapGetters({
      slideoutDetails: "dashboard/getSlideoutDetails",
    }),
  },
  methods: {
    _downloadURI(uri) {
      var link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeSlideout() {
      if (this.zoom) this.zoom.detach();
      this.$store.dispatch("dashboard/closeslideout");
    },
    wall_number(key) {
      const wall_id = key.charAt(5);
      if (wall_id === 5) {
        return "Interior";
      }
      return "Wall number: ".concat(wall_id);
    },
    hideLightbox() {
      this.lightboxImg = "";
      this.isLightboxVisible = false;
    },
    openImageModal(key, v) {
      this.lightboxImg = v;
      this.isLightboxVisible = true;

      // if (this.zoom) this.zoom.detach();
      // this.zoom = mediumZoom(`#${key}`, {
      //   scrollOffset: 40,
      //   container: {
      //     width: 1200,
      //     height: 730,
      //   },
      // });
      // this.zoom.on("close", () => this.zoom.detach());
    },
    async downloadPDF() {
      try {
        this.pdfButton.loading = true;
        const payload = {};
        const payloadArr = [];
        payload["Barcode1D"] = this.slideoutDetails.details[0].value;
        payload["Date_Time"] = this.slideoutDetails.details[1].value;
        payloadArr.push(payload);
        const URL = await this.$store.dispatch(
          "dashboard/downloadPDF",
          payloadArr
        );
        this._downloadURI(URL);
        this.pdfButton.type = "primary";
      } catch (err) {
        console.error(err);
        this.pdfButton.type = "error";
      } finally {
        this.pdfButton.loading = false;
      }
    },
    async downloadZip() {
      try {
        this.zipButton.loading = true;
        const payload = {};
        payload["Barcode1D"] = this.slideoutDetails.details[0].value;
        payload["Date_Time"] = this.slideoutDetails.details[1].value;
        const URL = await this.$store.dispatch(
          "dashboard/downloadZip",
          payload
        );
        this._downloadURI(URL.body);
        this.zipButton.type = "primary";
      } catch (err) {
        console.error(err);
        this.zipButton.type = "error";
      } finally {
        this.zipButton.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}

#image-acc .accordion-body-wrapper .accordion-body[open] {
  height: 60vh;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-37 {
  margin-left: 37px;
}

.medium-zoom-image {
  z-index: 100;
}

.medium-zoom-image--hidden {
  visibility: visible;
  background: var(--slideout-primary);
}

.img-div-inside-slideout {
  max-height: 300px;
  max-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.image-inside-slideout {
  object-fit: contain;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

.lighthouse_component {
  z-index: -111;
}
</style>
